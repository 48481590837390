dmx.Component('bootbox', {

  methods: {
    alert (options) {
      return new Promise (resolve => {
        bootbox.alert({ ...options, callback: resolve });
      });
    },
  },

});
