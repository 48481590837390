dmx.Actions({

  'bootbox.alert': function (options) {
    return new Promise (resolve => {
      bootbox.alert({ ...this.parse(options), callback: resolve });
    });
  },

  'bootbox.prompt': function (options) {
    return new Promise (resolve => {
      bootbox.prompt({ ...this.parse(options), callback: resolve });
    });
  },

  'bootbox.confirm': function (options) {
    options = Object.assign({}, options);

    const condition = {
      'then': options.then,
      'else': options.else,
    };

    delete options.then;
    delete options.else;

    return new Promise (resolve => {
      bootbox.confirm({ ...this.parse(options), callback: result => {
        if (result) {
          if (condition.then) {
            return resolve(this._exec(condition.then).then(() => result));
          }
        } else if (condition.else) {
          return resolve(this._exec(condition.else).then(() => result));
        }

        resolve(result);
      }});
    });
  },

});
